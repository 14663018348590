.search-wrapper {

  position: relative;

  & .sort-wrapper{
    background: @grey-3;
    padding: 5px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -46px;
    left: 0;
    right: 0;
    gap: 1em;
    flex-wrap: wrap;

    & .sort-wrapper-element{
      flex: auto;
      display: flex;
      align-items: center;
      gap: 1em;
      cursor: pointer;
    }
    & .sort-icons{
      display: flex;
      flex-direction: column;
      color: @white-color;
    }
    & .active{
      color: @black-color;
    }
  }

    & .search-loading{
      position: absolute;
      right: 20px;
    }

    & .search-input {
        width: 100%;
        height: 35px;
        font-size: 1rem;
        padding: 5px;
        border: 2px solid @grey-3;

      &:focus{
          outline: 0;
      }
        &::placeholder {
            color:#8C8C8C;
            font-weight: 300;
        }
    }

    & .result-wrapper{
        background: @white-color;
        display: flex;
        padding: 5px;
      border:  2px solid @grey-3;
      border-top: 0;
      z-index: 1;
      top: 63px;
        position: absolute;
      left: 0;
      right: 0;

        & .results{
          max-height: 300px;
          overflow: auto;

        }

        & > div{
          flex: 1 1 0
        }
        & .item{
          padding: @padding-md;
          border-bottom: 1px solid @grey-3;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & .search-icon{
            color: @primary-color
          }
          &:hover{
            background: @grey-2;
          }
           &.selected{
            background: @grey-2;
          }
        }

      & .selected-result{
        background: @grey-2;

        & .title{
          background: #fafafa;
          border-left: 1px solid @grey-3;
        }

        & .hits{
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          & .hit{
            padding: 5px;
          }
        }
        & .highlight{
          color: @red-color;
          font-weight: 700;

        }
      }
    }
}

@media only screen and (max-width: 768px) {
  .selected-result{
    display: none!important;
  }
  .sort-wrapper{
    flex-wrap: wrap;
    top: -130px!important;
  }
}
