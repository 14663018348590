.compare-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
  position: relative;
  & .attributes-row{
    grid-row: 2;
  }
  & .risk-row{
    grid-row: 3;
  }
  & .price-row{
    grid-row: 4;
  }
  & .capabilities-row{
    grid-row: 5;
  }
  & .my-row{
    grid-row: 5;
  }
  & .compare-row::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 2px 0px 0px 2px;
    border: 2px solid rgba(24, 144, 255, 0.5);
  }
  & .compare-row{
    grid-column: 1/end;
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px 0px 4px 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    
    position: relative;
    
  }
  & .risk-title{
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  & .company{
    margin-right: 20px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    & > div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;
      background: rgba(24, 144, 255, 0.05);
      z-index: 1;
    }

    & .company-title{
      grid-row: 1;
      position: relative;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;
      & span{
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    & .company-price{
      grid-row: 4;
      position: relative;
      & .price-info{
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    & .company-attributes{
      grid-row: 2;
      & .ant-statistic{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1em;
        justify-content: space-around;
        & > div{
          flex: 1 1 0;
          margin: 0;
          font-size: 14px;
          & > span{
            
          font-weight: bold;
          }
        }
      }
    }
    & .company-risk{
      grid-row: 3;
    }
  }
  & .company-1{
    grid-column: 2;
    grid-row: 1/end-line;
  }
  & .company-2{
    grid-column: 3;
    grid-row: 1/end-line;
  }
  & .company-3{
    grid-column: 4;
    grid-row: 1/end-line;
  }
  & .company-4{
    grid-column: 5;
    grid-row: 1/end-line;
  }
  & .company-5{
    grid-column: 5;
    grid-row: 1/end-line;

    & .company-title{
      height: fit-content;
      align-self: flex-end;
      > h2{
        margin: 0
      }
    }
  }

}