.welcomePageButtons {
    color: #4190F7;
    width: 150px;
    border: 10px solid #4190F7;
    border-radius: 50%;
    padding: 20px;
    margin: 20px;
    cursor: pointer;
    transition: transform .2s;
}
.welcomePageButtons:hover {
    transform: scale(1.1)
}
.welcomepage input::placeholder {
  color: gray !important;
}