.advanced-search{

  .ant-row{
    margin: 0
  }
  .ant-col{
    padding: 0;
  }

  & .search-items{
    padding: 15px;

    & .ant-list-item-meta{
      align-items: center!important;
    }
    & img{
      object-fit: contain;
    }

    & .item-details-wrapper{
      display: flex;
      font-size: 2em;
      gap: 0.3em;
      justify-content: center;
      color: @grey-4;

      & .icon-active{
        color: @primary-color;
      }
    }

  }

  & .search-sort{
    width: 100%;
    display: flex;
    padding: 10px;
    gap: 1em;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;

    & .sort-wrapper-element{
      flex: 1 1 0;
      cursor: pointer;

      & .sort-icons{
        display: flex;
        flex-direction: column;
        color: @white-color;
      }
      & .active{
        color: @black-color;
      }
    }
  }

  & .search-header{
    padding: 6px;
    border-bottom: 1px solid @grey-3;
    position: relative;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgb(29 42 61 / 5%);
    border-radius: 4px;
    margin-bottom: 14px;
    display: flex;
    justify-content: initial;
    align-items: center;

    > :first-child{
      margin: 0 10px;
    }

    & .search-icon{
      /* cursor: pointer;
      position: absolute;
      top: 11px;
      right: 11px;
      font-size: 1.7rem;
      padding: 6px 6px 6px 10px;
      width: 50px;
      color: @white-color;
      background: @primary-color;
      border-top-right-radius: 30px;
      height: 49px;
      border-bottom-right-radius: 30px;
      border: 1px solid #fafafa; */
      position: absolute;
      right: 17px;
      top: 14px;
      padding: 0px 34px;
    }
    & input{
      border: 0;
      height: 50px;
      width: 100%;
      outline: none;
    }
  }

  & .filters{
    height: 100%;
    border-right: 1px solid @grey-3;

    & .apply-filters{
      background: @primary-color;
      margin: 50px;
      gap: 1em;
      height: 40px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    & .filter-item{
      padding: 10px 10px;
      border-bottom: 1px solid #F0F0F0;
      margin: 15px 20px;
      & .single-filter{
        display: flex;
        color: rgb(38, 38, 38);
        flex-direction: row-reverse;
        font-weight: 400;
        font-size: 16px;
        align-items: center;
        margin-bottom: 1em!important;
        justify-content: space-between;
        & span{
          padding: 0;
        }
      }

      & .filter-item-boxes{
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        margin-top: 13px;
        & label{
          margin: 0;
        }
      }

      & .filter-item-title{
        cursor: pointer;
        display: flex;
        align-items: baseline;
        gap: 1em;
        color: black;
        & .filter-title{
          display: flex;
          gap: 1em;
          flex: 1 1 0;
          & span{
            font-weight: 300;
            color: #cec5c5;
          }
        }
      }
    }

    & .filters-title{
      background: #FAFAFA;
      border-bottom: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;
      padding: 20px 10px;
      text-align: center;
      display: flex;
      align-items: flex-start;
      gap: 1em;
    }
    & .filters-title-icon{
      display: none;
      font-size: 1.5em;
    }
  }
}
@media only screen and (max-width: 768px) {
  .filters-title-icon{
    display: inline-flex!important;
  }
  .sort-wrapper-element.full-width{
    width: 100%;
  }
  .search-icon{
    display: none;
  }
  .search-items{
    & .ant-list-item{
     flex-direction: column;
      & .ant-list-item-meta-avatar{
        align-self: flex-start;
      }
      & .ant-list-item-meta{
        flex-direction: column;
      }
    }
  }
}