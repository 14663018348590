.progress-wrapp{
    display: flex;
    flex-direction: column;
}

.progress-section{
    position: absolute;
}

.progress-section::after{
    content: '';
    width: 1px;
    height: 20px;
    position: absolute;
    border: 1px solid black;
    top: -28px;
    left: 0;
}

.icon-wrapper div.ant-progress-steps-item {
    width: 35px !important;
}

.icon-wrapper .anticon svg{
    font-size: 15px;
    margin: 0 8px;
}

.section-20{
    left: 20%;
}
.section-40{
    left: 40%;
}
.section-60{
    left: 60%;
}
.section-80{
    left:  80%;
}