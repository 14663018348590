.principal-wrapper{
  display: flex;
  flex-wrap: wrap;
  & .principal{
    display: flex;
    border-left: 4px solid rgba(24, 144, 255, 0.4);
    position: relative;
    flex: 1 1 200px;
    padding: 10px;
    min-height: 85px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-right: 10px;
    & .title{
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  & .senior{
    background: #F0FAFF;
  }

  & .standard{
    background: #FAFAFA;
  }
}

.show-more{
  padding: 10px;
  text-align: center;
  background: @grey-3;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
}